export function scrollToError(): void {
  const elemenet = document.querySelector('[data-error]');
  if (elemenet) {
    elemenet.scrollIntoView();
  }
}

export function keyBy<T>(key: keyof T, values: T[]): { [key: string]: T } {
  return values.reduce<{
    [key: string]: T;
  }>((accumulator, current: T) => {
    accumulator[String(current[key])] = current;
    return accumulator;
  }, {});
}
