






































































import Vue from 'vue';
import MultiSelect from 'vue-multiselect';

import DnbIcon from '@/components/DnbIcon.vue';
import DnbWarning from '@/components/DnbWarning.vue';
import HelpText from '@/components/HelpText.vue';

type Option = { label: string; value: unknown };

export default Vue.extend({
  name: 'dnb-select',
  components: { DnbIcon, MultiSelect, HelpText, DnbWarning },
  props: {
    value: { type: [String, Array as () => string[]], default: undefined },
    id: { type: String, default: undefined },
    label: { type: String, default: undefined },
    validationError: { type: Array, default: undefined },
    options: { type: Array as () => Option[], default: () => [] },
    multiple: { type: Boolean, default: false },
    searchable: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    help: { type: Boolean, default: false },
  },
  data(): {
    selectedHelpText: string;
    displayHelpText: boolean;
  } {
    return {
      selectedHelpText: '',
      displayHelpText: false,
    };
  },
  computed: {
    selectedOption(): Option | Option[] | undefined {
      if (this.multiple) {
        const selectedOptions = (
          typeof this.value === 'string' ? [this.value] : this.value
        )
          .map((value: string) =>
            this.options.find((option: Option) => option.value === value),
          )
          .filter((item) => item);
        return selectedOptions as Option[]; // tsc does not understand the filter
      } else {
        return this.options.find(
          (option: Option) => option.value === this.value,
        );
      }
    },
  },

  methods: {
    remove(option: Option) {
      if (this.multiple) {
        this.$emit(
          'input',
          (typeof this.value === 'string' ? [this.value] : this.value).filter(
            (item: string) => item !== option.value,
          ),
        );
      } else {
        this.$emit('input', option.value);
      }
    },
    select(option: Option) {
      if (this.multiple) {
        if (typeof this.value === 'string') {
          this.$emit('input', [this.value, option.value]);
        } else {
          this.$emit('input', [...this.value, option.value]);
        }
      } else {
        this.$emit('input', option.value);
      }
    },
    setSelectedHelpText(text: string) {
      this.selectedHelpText = text;
      this.displayHelpText = !this.displayHelpText;
      if (!this.displayHelpText) {
        this.selectedHelpText = '';
      }
    },
    resetHelpText() {
      this.selectedHelpText = '';
      this.displayHelpText = false;
    },
  },
});
