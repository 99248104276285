import { render, staticRenderFns } from "./DnbSelect.vue?vue&type=template&id=6a6de93a&"
import script from "./DnbSelect.vue?vue&type=script&lang=ts&"
export * from "./DnbSelect.vue?vue&type=script&lang=ts&"
import style0 from "./DnbSelect.vue?vue&type=style&index=0&lang=scss&module=true&"
import style1 from "./DnbSelect.vue?vue&type=style&index=1&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports